<template>
	<v-card>
		<v-card-title>API Base URL https://class.whattheduck.app/api</v-card-title>
		<v-card-text>
			For a list of potential error messages please refer to the below links.
			Majority of the firebase ones will be as defined, some custom errors attempt to follow similar format as the firebase defined ones.
			https://firebase.google.com/docs/auth/admin/errors
		</v-card-text>
		<v-card-text>
			<v-expansion-panels>
				<v-expansion-panel
					v-for="(item,i) in endpoints"
					:key="i"
				>
					<v-expansion-panel-header>
						{{item.endpoint}}
						
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-card>
							<v-card-title>Method: {{item.method}}</v-card-title>
							<v-card-subtitle>{{item.description}}</v-card-subtitle>
							<v-card-subtitle>Parameters</v-card-subtitle>
							<v-card-text>
								<div
									class="ml-5"
									v-for="(p, pi) in item.parameters"
									:key="pi"
								>
									<div><span class="font-weight-bold">{{p.key}}</span>: {{p.type}} {{p.required?'required':'optional'}}</div>
									<div
										class="ml-5"
										v-if="p.notes && p.notes.length > 0"
									>
										<div class="h5">Notes</div>
										<ul>
											<li
												class="ml-5"
												v-for="(n,ni) in p.notes"
												:key="ni"
											>{{n}}</li>
										</ul>
									</div>
								</div>
							</v-card-text>
							<v-card-subtitle>Response</v-card-subtitle>
							<v-card-text v-if="item.response">
								<div class="h5">Success</div>
								<pre>{{JSON.stringify(JSON.parse(item.response.success), null, 2)}}
                  </pre>
								<div class="h5">Error</div>
								<pre>{{JSON.stringify(JSON.parse(item.response.error), null, 2)}}
                  </pre>
							</v-card-text>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	mounted(){
		this.endpoints.forEach(item=>{
			console.log(item.endpoint);
			console.log(item.response);
			JSON.parse(item.response.success);
			JSON.parse(item.response.error);
		});
	},
	data() {
		return {
			endpoints: [
				{
					endpoint: "/login",
					description: "Endpoint to login a user",
					method: "post",
					parameters: [
						{
							key: "api-key",
							type: "string",
							required: true,
							notes: [
								"Send this as a header, not the request body",
								"Get this from your profile page",
							],
						},
						{
							key: "email",
							type: "string",
							required: true,
							notes: [],
						},
						{
							key: "password",
							type: "string",
							required: true,
							notes: ["Raw password", "Minimum 8 characters"],
						},
					],
					response: {
						error: `
                            {
                                "status": "integer - http code",
                                "error":{
                                    "code": "string - name",
                                    "message": "string - details"
                                }
                            }
                        `,
						success: `
                            {
                                "status": "integer - http code",
                                "data": {
                                    "uid": "string - unique user id",
                                    "email": "string",
                                    "emailVerified": "boolean",
                                    "disabled": "boolean",
                                    "metadata": {
                                        "lastSignInTime": "Fri, 26 Mar 2021 01:05:45 GMT",
                                        "creationTime": "Thu, 25 Mar 2021 00:57:40 GMT"
                                    },
                                    "tokensValidAfterTime": "Thu, 25 Mar 2021 00:57:40 GMT",
                                    "providerData": [{
                                        "uid": "test@test.com",
                                        "email": "test@test.com",
                                        "providerId": "password"
                                    }]
                                }
                            }
                        `,
					},
				},
				{
					endpoint: "/register",
					description: "Endpoint to register a new user",
					method: "post",
					parameters: [
						{
							key: "api-key",
							type: "string",
							required: true,
							notes: [
								"Send this as a header, not the request body",
								"Get this from your profile page",
							],
						},
						{
							key: "email",
							type: "string",
							required: true,
							notes: [],
						},
						{
							key: "username",
							type: "string",
							required: false,
							notes: ["If ommitted will use the email address before the @"],
						},
						{
							key: "password",
							type: "string",
							required: true,
							notes: ["Raw password", "Minimum 8 characters"],
						},
					],
					response: {
						error: `
                            {
                                "status": "integer - http code",
                                "error":{
                                    "code": "string - name",
                                    "message": "string - details"
                                }
                            }
                        `,
						success: `
                            {
                                "status": "integer - http code",
                                "data": {
                                    "uid": "string - unique user id",
                                    "email": "string",
                                    "emailVerified": "boolean",
                                    "disabled": "boolean",
                                    "metadata": {
                                        "lastSignInTime": "Fri, 26 Mar 2021 01:05:45 GMT",
                                        "creationTime": "Thu, 25 Mar 2021 00:57:40 GMT"
                                    },
                                    "tokensValidAfterTime": "Thu, 25 Mar 2021 00:57:40 GMT",
                                    "providerData": [{
                                        "uid": "test@test.com",
                                        "email": "test@test.com",
                                        "providerId": "password"
                                    }]
                                }
                            }
                        `,
					},
				},
				{
					endpoint: "/updateProfile",
					description: "Endpoint to update user info",
					method: "post",
					parameters: [
						{
							key: "api-key",
							type: "string",
							required: true,
							notes: [
								"Send this as a header, not the request body",
								"Get this from your profile page",
							],
						},
						{
							key: "uid",
							type: "string",
							required: true,
							notes: [],
						},
						{
							key: "email",
							type: "string",
							required: false,
							notes: [],
						},
						{
							key: "password",
							type: "string",
							required: false,
							notes: [
								"Raw password",
								"Minimum 8 characters",
								"Resets the user's current password on success",
							],
						},
						{
							key: "phoneNumber",
							type: "string",
							required: false,
							notes: [],
						},
						{
							key: "username",
							type: "string",
							required: false,
							notes: ["This is the displayName property of a user"],
						},
						{
							key: "photoURL",
							type: "string",
							required: false,
							notes: [],
						},
						{
							key: "disabled",
							type: "boolean",
							required: false,
							notes: [
								"This disables a user account",
								"This cannot be undone via this API and should be considered permenant",
							],
						},
					],
					response: {
						error: `
                            {
                                "status": "integer - http code",
                                "error":{
                                    "code": "string - name",
                                    "message": "string - details"
                                }
                            }
                        `,
						success: `
                            {
                                "status": "integer - http code",
                                "data": {
                                    "uid": "string - unique user id",
                                    "email": "string",
                                    "emailVerified": "boolean",
                                    "disabled": "boolean",
									"photoURL":"profile picture url",
                                    "metadata": {
                                        "lastSignInTime": "Fri, 26 Mar 2021 01:05:45 GMT",
                                        "creationTime": "Thu, 25 Mar 2021 00:57:40 GMT"
                                    },
                                    "tokensValidAfterTime": "Thu, 25 Mar 2021 00:57:40 GMT",
                                    "providerData": [{
                                        "uid": "test@test.com",
                                        "email": "test@test.com",
                                        "providerId": "password"
                                    }]
                                }
                            }
                        `,
					},
				},
				{
					endpoint: "/getProfile",
					description: "Endpoint to get user info",
					method: "get",
					parameters: [
						{
							key: "api-key",
							type: "string",
							required: true,
							notes: [
								"Send this as a header, not the request body",
								"Get this from your profile page",
							],
						},
						{
							key: "uid",
							type: "string",
							required: true,
							notes: [],
						},
					],
					response: {
						error: `
                            {
                                "status": "integer - http code",
                                "error":{
                                    "code": "string - name",
                                    "message": "string - details"
                                }
                            }
                        `,
						success: `
                            {
                                "status": "integer - http code",
                                "data": {
                                    "uid": "string - unique user id",
                                    "email": "string",
                                    "emailVerified": "boolean",
                                    "disabled": "boolean",
                                    "photoURL":"profile picture url"
                                }
                            }
                        `,
					},
				},
				{
					endpoint: "/saveScore",
					description: "Endpoint to record a score for a user (applies points and experience)",
					method: "post",
					parameters: [
						{
							key: "api-key",
							type: "string",
							required: true,
							notes: [
								"Send this as a header, not the request body",
								"Get this from your profile page",
							],
						},
						{
							key: "appKey",
							type: "string",
							required: true,
							notes: ["Identifier of your app, recommend to use the package name/indentifier (i.e., njit.mt.test [though don't use mine specifically])"]
						},
						{
							key: "uid",
							type: "string",
							required: true,
							notes: [],
						},
						{
							key: "score",
							type: "number",
							required: true,
							notes: ["Must be > 0 or <= max score"]
						},
						{
							key: "maxScore",
							type: "number",
							required: true,
							notes:["Must be > 0 and >= score"]
						}
					],
					response: {
						error: `
                            {
                                "status": "integer - http code",
                                "error":{
                                    "code": "string - name",
                                    "message": "string - details"
                                }
                            }
                        `,
						success: `
                            {
                                "status": "integer - http code",
                                "data": {
                                    "uid": "string - unique user id",
                                    "points": "integer - user's total points",
									"experience": "integer - user's total experience",
									"level": "integer - user's level based on experience",
									"plays": "Map of AppKey to # of plays for the user",
									"displayName": "The name of the user"
                                }
                            }
                        `,
					},
				},
				{
					endpoint: "/getScores",
					description: "Endpoint to get scores (top or history for app and/or user)",
					method: "get",
					parameters: [
						{
							key: "api-key",
							type: "string",
							required: true,
							notes: [
								"Send this as a header, not the request body",
								"Get this from your profile page",
							],
						},
						{
							key: "appKey",
							type: "string",
							required: true,
							notes: ["Identifier of your app, recommend to use the package name/indentifier (i.e., njit.mt.test [though don't use mine specifically])"]
						},
						{
							key: "uid",
							type: "string",
							required: false,
							notes: ["If supplied will filter the results to only this user"],
						},
						{
							key: "type",
							type: "string",
							required: true,
							notes: ["Must either be 'history' or 'top'. History sorts by created desc and Top sorts by Score desc."]
						},
						{
							key: "startAfter",
							type: "string",
							required: false,
							notes:["A Score id to continue pagination after (since you may only get a max of 10 results at a time)"]
						}
					],
					response: {
						error: `
                            {
                                "status": "integer - http code",
                                "error":{
                                    "code": "string - name",
                                    "message": "string - details"
                                }
                            }
                        `,
						success: `
                            {
                                "status": "integer - http code",
                                "data": [{
                                    "uid": "string - unique user id",
                                    "score": "integer - user's score for this attempt",
									"appKey": "string - app it belongs to",
									"created": "string - timestamp of when the score was achieved",
									"id": "The id of the particular score entry (used for pagination via startAfter param)",
									"displayName": "The name of the user"
                                }]
                            }
                        `,
					},
				},
				{
					endpoint: "/getData",
					description: "Endpoint to get arbitrary saved data in JSON format",
					method: "get",
					parameters: [
						{
							key: "api-key",
							type: "string",
							required: true,
							notes: [
								"Send this as a header, not the request body",
								"Get this from your profile page",
							],
						},
						{
							key: "appKey",
							type: "string",
							required: true,
							notes: ["Identifier of your app, recommend to use the package name/indentifier (i.e., njit.mt.test [though don't use mine specifically])"]
						},
						{
							key: "uid",
							type: "string",
							required: false,
							notes: ["If supplied will filter the results to only this user"],
						},
						{
							key: "key",
							type: "string",
							required: true,
							notes: ["Must be a valid firebase autoId (returned from a 'NEW' setdata call)"
							, "(new) Alternatively if you pass a key 36+ it'll allow custom keys to be used (see shop example)"]
						}
					],
					response: {
						error: `
                            {
                                "status": "integer - http code",
                                "error":{
                                    "code": "string - name",
                                    "message": "string - details"
                                }
                            }
                        `,
						success: `
                            {
                                "status": "integer - http code",
                                "data": {
                                    "uid": "string - unique user id",
                                    "key": "string - the firebase autoId of the generated document",
									"appKey": "string - app it belongs to",
									"value": "string - json string of the data that was previous stored",
									"created": {
										"_seconds": "1618413943",
										"_nanoseconds": "797000000"
									},
									"updated": {
										"_seconds": "1618414038",
										"_nanoseconds": "645000000"
									}
                                }
                            }
                        `,
					},
				},
				{
					endpoint: "/getDataByAppKey",
					description: "Endpoint to get a list of arbitrary saved data in JSON format",
					method: "get",
					parameters: [
						{
							key: "api-key",
							type: "string",
							required: true,
							notes: [
								"Send this as a header, not the request body",
								"Get this from your profile page",
							],
						},
						{
							key: "appKey",
							type: "string",
							required: true,
							notes: ["Identifier of your app, recommend to use the package name/indentifier (i.e., njit.mt.test [though don't use mine specifically])"]
						},
					],
					response: {
						error: `
                            {
                                "status": "integer - http code",
                                "error":{
                                    "code": "string - name",
                                    "message": "string - details"
                                }
                            }
                        `,
						success: `
                            {
                                "status": "integer - http code",
                                "data": [{
                                    "uid": "string - unique user id",
                                    "key": "string - the firebase autoId of the generated document",
									"appKey": "string - app it belongs to",
									"value": "string - json string of the data that was previous stored",
									"created": {
										"_seconds": 1618413943,
										"_nanoseconds": 797000000
									},
									"updated": {
										"_seconds": 1618414038,
										"_nanoseconds": 645000000
									}
                                }]
                            }
                        `,
					},
				},
				{
				endpoint: "/setData",
				description: "Endpoint to save arbitrary data in JSON format",
					method: "post",
					parameters: [
						{
							key: "api-key",
							type: "string",
							required: true,
							notes: [
								"Send this as a header, not the request body",
								"Get this from your profile page",
							],
						},
						{
							key: "appKey",
							type: "string",
							required: true,
							notes: ["Identifier of your app, recommend to use the package name/indentifier (i.e., njit.mt.test [though don't use mine specifically])"]
						},
						{
							key: "uid",
							type: "string",
							required: false,
							notes: ["If supplied will set data for this user"],
						},
						{
							key: "key",
							type: "string",
							required: true,
							notes: ["Must be a valid firebase autoId if updating a record or 'NEW' if saving for the first time (the result will contain a key to reuse)",
							"(new) Alternatively if you pass a key 36+ it'll allow custom keys to be used (see shop example)"]
						},
						{
							key: "value",
							type: "string",
							required: true,
							notes: ["Valid JSON string of desired data to save/update"]
						},
					],
					response: {
						error: `
                            {
                                "status": "integer - http code",
                                "error":{
                                    "code": "string - name",
                                    "message": "string - details"
                                }
                            }
                        `,
						success: `
                            {
                                "status": "integer - http code",
                                "data": {
                                    "uid": "string - unique user id",
                                    "key": "string - the firebase autoId of the generated document",
									"appKey": "string - app it belongs to",
									"value": "string - json string of the data that was previous stored"
                                }
                            }
                        `,
					},
				},
				{
					endpoint: "/getStats",
					description: "Endpoint to get the stats of a user (points, experience, level, plays, displayName)",
					method: "get",
					parameters: [
						{
							key: "api-key",
							type: "string",
							required: true,
							notes: [
								"Send this as a header, not the request body",
								"Get this from your profile page",
							],
						},
						{
							key: "uid",
							type: "string",
							required: true,
							notes: [],
						},
					],
					response: {
						error: `
                            {
                                "status": "integer - http code",
                                "error":{
                                    "code": "string - name",
                                    "message": "string - details"
                                }
                            }
                        `,
						success: `
                            {
                                "status": "integer - http code",
                                "data": {
                                    "uid": "string - unique user id",
                                    "points": "integer - user's total points",
									"experience": "integer - user's total experience",
									"level": "integer - user's level based on experience",
									"plays": "Map of AppKey to # of plays for the user",
									"displayName": "The name of the user"
                                }
                            }
                        `,
					},
				},
				{
					endpoint: "/changePoints",
					description: "Endpoint to arbitrarily change points of a user (positive or negative). Negative points can't bring user's points below 0. NOTE: No real validation so it just happens.",
					method: "post",
					parameters: [
						{
							key: "api-key",
							type: "string",
							required: true,
							notes: [
								"Send this as a header, not the request body",
								"Get this from your profile page",
							],
						},
						{
							key: "uid",
							type: "string",
							required: true,
							notes: [],
						},
						{
							key: "points",
							type: "number",
							required: true,
							notes:["Make negative for a purchase or positive for a reward","Don't waste resources by passing 0"]
						}
					],
					response: {
						error: `
                            {
                                "status": "integer - http code",
                                "error":{
                                    "code": "string - name",
                                    "message": "string - details"
                                }
                            }
                        `,
						success: `
                            {
                                "status": "integer - http code",
                                "data": {
                                    "uid": "string - unique user id",
                                    "points": "integer - user's total points",
									"experience": "integer - user's total experience",
									"level": "integer - user's level based on experience",
									"plays": "Map of AppKey to # of plays for the user",
									"displayName": "The name of the user"
                                }
                            }
                        `,
					},
				},
				{
					endpoint: "/randomReward",
					description: "Potentially generates and updates the user's balance with a random reward value. Rewards given only once per day per user.",
					method: "post",
					parameters: [
						{
							key: "api-key",
							type: "string",
							required: true,
							notes: [
								"Send this as a header, not the request body",
								"Get this from your profile page",
							],
						},
						{
							key: "uid",
							type: "string",
							required: true,
							notes: ["Person to receive the rewards"],
						},
						{
							key: "reward",
							type: "string",
							required: true,
							notes:["small for 0-100 points","large for 5% chance of 500-3000 points"]
						}
					],
					response: {
						error: `
                            {
                                "status": "integer - http code",
                                "error":{
                                    "code": "string - name",
                                    "message": "string - details"
                                }
                            }
                        `,
						success: `
                            {
                                "status": "integer - http code",
                                "data": {
                                    "uid": "string - unique user id",
                                    "points": "integer - user's total points",
									"experience": "integer - user's total experience",
									"level": "integer - user's level based on experience",
									"plays": "Map of AppKey to # of plays for the user",
									"displayName": "The name of the user"
                                }
                            }
                        `,
					},
				},
				{
					endpoint: "/upload",
					description: "Allows persistance of an image via a base64 string. Value is stored in a collection and a url is returned. Can be applied to profile photoURL. Note: Collection is not yet exposed.",
					method: "post",
					parameters: [
						{
							key: "api-key",
							type: "string",
							required: true,
							notes: [
								"Send this as a header, not the request body",
								"Get this from your profile page",
							],
						},
						{
							key: "uid",
							type: "string",
							required: true,
							notes: ["Person to receive the rewards"],
						},
						{
							key: "appKey",
							type: "string",
							required: true,
							notes:["Commonly application package"]
						},
						{
							key: "data",
							type: "string",
							required: true,
							notes:["base64 encoded string"]
						}
					],
					response: {
						error: `
                            {
                                "status": "integer - http code",
                                "error":{
                                    "code": "string - name",
                                    "message": "string - details"
                                }
                            }
                        `,
						success: `
                            {
                                "status": "integer - http code",
                                "data": {
                                    "link":"firebase storage url"
                                }
                            }
                        `,
					},
				},
			],
		};
	},
};
</script>